<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <keep-alive><router-view /></keep-alive>
  <!-- <router-view /> -->
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    // 注册全局前置守卫
    router.beforeEach((to, from, next) => {
      next(); // 继续导航
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: "微软雅黑", Arial, "宋体" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
* {
  padding: 0;
  margin: 0;
}
</style>
